import { initializeApp } from 'firebase/app';
import { browserLocalPersistence, getAuth, setPersistence } from 'firebase/auth';
import { initializeFirestore, persistentLocalCache } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyD3AWY3Q23PrDgm_DeJSh5BF_1kdYJFFbI",
  authDomain: "kahaniexpress-743ad.firebaseapp.com",
  projectId: "kahaniexpress-743ad",
  storageBucket: "kahaniexpress-743ad.appspot.com",
  messagingSenderId: "1061575376016",
  appId: "1:1061575376016:web:215819120b6afbe11f2c15",
  measurementId: "G-2KT6LJ3Q1R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
export const auth = getAuth(app);

// Set persistence so user stays logged in across page reloads
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log('Auth persistence set to browserLocalPersistence');
  })
  .catch((err) => {
    console.error('Error setting persistence:', err);
  });

// Initialize Firestore
export const db = initializeFirestore(app, {
  localCache: persistentLocalCache()
});