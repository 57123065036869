import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import axiosClient from '../api/axiosClient';
import { config } from '../Common/Constants';
import { auth } from '../firebase';
const URL = config.url;
// Async thunk for signing up a new user
export const signupUser = createAsyncThunk(
  'user/signupUser',
  async ({ firstName, lastName, email, password }, { rejectWithValue }) => {
    try {
      // Create user with Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Update Firebase user profile with display name
      await updateProfile(user, {
        displayName: `${firstName} ${lastName}`,
      });
      const New_URL = `${URL}/signup`;
      // Send a POST request to Node.js backend to insert the welcome message
      await axiosClient.post( New_URL, {
        username: `${firstName} ${lastName}`,
        email,
        userId: user.uid // Firebase user ID to link messages
      });

      // Return user data to be stored in Redux store
      return {
        uid: user.uid,
        displayName: user.displayName,
        email: user.email
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  user: null,
  isAuthenticated: false,
  error: null,
  loading: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logoutUser: (state) => { 
      state.user = null;
      state.isAuthenticated = false;
      console.log('Logging out user...');
    },
    loginUser: (state, action) => {
      const { uid, displayName, email,  } = action.payload;
      state.user = { uid, displayName, email }; // Store serializable data
      state.isAuthenticated = true; // Set authenticated flag
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signupUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signupUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(signupUser.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { loginUser, logoutUser } = userSlice.actions;

export default userSlice.reducer;
