// storySlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosClient from '../api/axiosClient';
import { config } from '../Common/Constants';

const URL = config.url;

export const fetchStoryBySlug = createAsyncThunk(
  'story/fetchStoryBySlug',
  async (slug, thunkAPI) => {
    try {
      const response = await axiosClient.get(`${URL}/KahaniJson/slug/${slug}`);
      
      // Check if no data is returned
      if (!response.data || Object.keys(response.data).length === 0) {
        return thunkAPI.rejectWithValue("No story data returned from server.");
      }
      
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchStoryById = createAsyncThunk(
  'story/fetchStoryById',
  async (storyId, thunkAPI) => {
    try {
      const New_URL = `${URL}/StoryJson/${storyId}`;
      const response = await axiosClient.get(New_URL);
      if (response.data && response.data.storybook) {
        const storybook = JSON.parse(response.data.storybook);
        return storybook;
      } else {
        return thunkAPI.rejectWithValue('Invalid story data structure');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || 'Error fetching story');
    }
  }
);

const storySlice = createSlice({
  name: 'story',
  initialState: {
    story: null,
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStoryBySlug.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchStoryBySlug.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.story = action.payload;
      })
      .addCase(fetchStoryBySlug.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchStoryById.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchStoryById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.story = action.payload;
      })
      .addCase(fetchStoryById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      });
  },
});

export default storySlice.reducer;
