import { Create, Leaderboard } from '@mui/icons-material';
import BookIcon from '@mui/icons-material/Book';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import MessageIcon from '@mui/icons-material/Message';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../Extra/Kahani Express_transparent-.png'; // Corrected path for the logo

function DrawerContents(handleDrawerToggle) {
  const user = useSelector((state) => state.user); // Get user from Redux store
  const isUserAuthorized = (uid) => {
    return uid === "0nLbmWKwuuVZ8ceasiH1q9O4WB73" || uid === "xfr7VGt6ScZ4rpBkfhIyJv4Zmzy1";
  };
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px 0' }}>
        <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }} onClick={handleDrawerToggle}>
          <img src={logo} alt="Kahani Express Logo" style={{ width: '80%', height: 'auto' }} />
        </Link>
      </Box>
      <List>
        <ListItem button component={Link} to="/Home" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <HomeIcon sx={{ color: '#3a3a3a' }} />
          </ListItemIcon>
          <ListItemText primary="Home" sx={{ color: '#3a3a3a' }} />
        </ListItem>
        <ListItem button component={Link} to="/about" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <InfoIcon sx={{ color: '#3a3a3a' }} />
          </ListItemIcon>
          <ListItemText primary="About Us" sx={{ color: '#3a3a3a' }} />
        </ListItem>
        <ListItem button component={Link} to="/stories" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <BookIcon sx={{ color: '#3a3a3a' }} />
          </ListItemIcon>
          <ListItemText primary="Stories" sx={{ color: '#3a3a3a' }} />
        </ListItem>
        <ListItem button component={Link} to="/CreateStory" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <Create sx={{ color: '#3a3a3a' }} />
          </ListItemIcon>
          <ListItemText primary="Create your Story" sx={{ color: '#3a3a3a' }} />
        </ListItem>
        <ListItem button component={Link} to="/MessageBoard" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <MessageIcon sx={{ color: '#3a3a3a' }} />
          </ListItemIcon>
          <ListItemText primary="MessageBoard" sx={{ color: '#3a3a3a' }} />
        </ListItem>
        <ListItem button component={Link} to="/LeaderBoard" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <Leaderboard sx={{ color: '#3a3a3a' }} />
          </ListItemIcon>
          <ListItemText primary="LeaderBoard" sx={{ color: '#3a3a3a' }} />
        </ListItem>

        {/* Show UploadSVG only if user is logged in and has uid "abc" */}
        { isUserAuthorized(user?.user?.uid)
        && (
          <ListItem button component={Link} to="/uploadSVG" onClick={handleDrawerToggle}>
            <ListItemIcon>
              <MessageIcon sx={{ color: '#3a3a3a' }} />
            </ListItemIcon>
            <ListItemText primary="Upload SVG" sx={{ color: '#3a3a3a' }} />
          </ListItem>
        )}
       { isUserAuthorized(user?.user?.uid) && (
        <ListItem button component={Link} to="/StoryModificationGrid" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <MessageIcon sx={{ color: '#3a3a3a' }} />
          </ListItemIcon>
          <ListItemText primary="Modification" sx={{ color: '#3a3a3a' }} />
        </ListItem>

      )}
      </List>
    </Box>
  );
}

export default DrawerContents;
