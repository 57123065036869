import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosClient from '../api/axiosClient';

const initialState = {
  highlightedIndex: null,
  alignment: null,
  audioUrl: null,
  status: 'idle',
  error: null,
  playbackRate: 1.0,
  isPlaying: false,
};

export const fetchAudioAndAlignment = createAsyncThunk(
  'narration/fetchAudioAndAlignment',
  async ({ text, storyId, page_no, language, regenerateAudio = false }, { rejectWithValue }) => {
    try {
      if (!text || storyId === undefined || page_no === undefined) {
        throw new Error('Missing required parameters for fetching audio');
      }

      const url =
        process.env.NODE_ENV === 'development'
          ? 'http://localhost:8080'
          : 'https://kahaninodeglobal.azurefd.net';

      const New_URL = `${url}/fetchAudioAndAlignment`;

      const response = await axiosClient.post(New_URL, { text, storyId, page_no, language, regenerateAudio });
      const data = await response.data;
      if (!data.alignment || !data.audioBase64) {
        throw new Error('Invalid data received from server');
      }

      // Safe base64 decoding
      const binaryString = atob(data.audioBase64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const audioBlob = new Blob([bytes.buffer], { type: 'audio/mpeg' });
      const audioUrl = URL.createObjectURL(audioBlob);

      return { alignment: data.alignment, audioUrl };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const narrationSlice = createSlice({
  name: 'narration',
  initialState,
  reducers: {
    setHighlightedIndex: (state, action) => {
      state.highlightedIndex = action.payload;
    },
    resetHighlightedIndex: (state) => {
      state.highlightedIndex = null;
    },
    setPlaybackRate: (state, action) => {
      state.playbackRate = action.payload;
    },
    startPlayback: (state) => {
      state.isPlaying = true;
    },
    stopPlayback: (state) => {
      state.isPlaying = false;
    },
    setIsPlaying: (state, action) => {
      state.isPlaying = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAudioAndAlignment.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.alignment = null;
        state.audioUrl = null;
      })
      .addCase(fetchAudioAndAlignment.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.alignment = action.payload.alignment;
        state.audioUrl = action.payload.audioUrl;
      })
      .addCase(fetchAudioAndAlignment.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      });
  },
});

export const {
  setHighlightedIndex,
  resetHighlightedIndex,
  setPlaybackRate,
  setIsPlaying,
} = narrationSlice.actions;
export default narrationSlice.reducer;
