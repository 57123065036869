// src/features/createStorySlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosClient from '../api/axiosClient';
import { config } from '../Common/Constants';

const initialState = {
  storyName: '',
  storyContent: '',
  author: '',
  status: 'idle', // Tracks the status of the API request
  error: null,    // Stores error messages if submission fails
};

const URL = config.url;

// Async thunk to handle story submission
export const submitCreateStory = createAsyncThunk(
  'createStory/submitCreateStory',
  async (storyRequest, { rejectWithValue }) => {
    try {
      const New_URL = `${URL}/storiesRequest`;
      const response = await axiosClient.post(New_URL, { storyRequest });

      return response.data; 
  
    } catch (error) {
      return rejectWithValue(error.message); // If there's an error, reject with the error message
    }
  }
);

const createStorySlice = createSlice({
  name: 'createStory',
  initialState,
  reducers: {
    updateCreateStoryForm: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value; // Dynamically update the form fields
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitCreateStory.pending, (state) => {
        state.status = 'loading'; // API call is in progress
        state.error = null;       // Reset any previous errors
      })
      .addCase(submitCreateStory.fulfilled, (state) => {
        state.status = 'succeeded'; // API call was successful
        // Reset the form fields, but DO NOT return initialState
        state.storyName = '';
        state.storyContent = '';
        state.author = '';
      })
      .addCase(submitCreateStory.rejected, (state, action) => {
        state.status = 'failed';    // API call failed
        state.error = action.payload; // Set the error message from the rejected thunk
      });
  },
});

export const { updateCreateStoryForm } = createStorySlice.actions;
export default createStorySlice.reducer;
