import axios from 'axios';
import { config } from '../Common/Constants';
import { auth } from '../firebase'; // Import your Firebase auth instance

const axiosClient = axios.create({
  baseURL: config.url, // Your base API URL
  // headers: {
  //   'Content-Type': 'application/json',
  // },
});

// Request interceptor
axiosClient.interceptors.request.use(
  async (config) => {
    // Get the current user from Firebase auth
    const user = auth.currentUser;
    if (user) {
      // Get the JWT token from Firebase
      const token = await user.getIdToken(true); // Force refresh
      // Set the Authorization header
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor (optional, for handling errors globally)
axiosClient.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // You can add global error handling here, e.g., display a notification
    return Promise.reject(error);
  }
);

export default axiosClient;