// App.js
import { onAuthStateChanged } from 'firebase/auth';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ProtectedRoute from './Common/ProtectedRoute';
import Layout from './Components/Home/Layout';
import { fetchStories } from './features/storiesSlice';
import { auth } from './firebase';

// Lazy loaded components
const Home = lazy(() => import('./Pages/Home/Home'));
const AboutUs = lazy(() => import('./Pages/Home/AboutUs'));
const StorySelector = lazy(() => import('./Pages/Story/StorySelector'));
const StoryViewer = lazy(() => import('./Pages/Story/StoryViewer'));
const StoryModification = lazy(() => import('./Pages/Modification/StoryModification'));
const StoryModificationGrid = lazy(() => import('./Pages/Modification/StoryModificationGrid'));
const Login = lazy(() => import('./Pages/Home/Login'));
const Signup = lazy(() => import('./Pages/Home/Signup'));
const CreateStory = lazy(() => import('./Pages/Modification/CreateStory'));
const MessageBoard = lazy(() => import('./Pages/Home/MessageBoard'));
const PrivacyPolicy = lazy(() => import('./Pages/Home/PrivacyPolicy'));
const ForgotPassword = lazy(() => import('./Pages/Home/ForgotPassword'));
const Leaderboard = lazy(() => import('./Pages/Game/Leaderboard'));
const Show = lazy(() => import('./Pages/Anime/Show'));
const SVGManagerPage = lazy(() => import('./Pages/Modification/SVGManagerPage'));
const DownloadApp = lazy(() => import('./Pages/Home/DownloadApp'));

function App() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser ? currentUser : null);
      if (currentUser) {
        dispatch(fetchStories());
      }
    });
    return () => unsubscribe();
  }, [dispatch]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            path="/"
            element={
              <Layout mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} user={user} />
            }
          >
            <Route index element={<StorySelector />} />
            <Route path="Home" element={<Home />} />
            <Route path="about" element={<AboutUs />} />
            <Route path="stories" element={<StorySelector />} />
            {/* Updated route for viewing a story by slug */}
            <Route path="stories/:slug" element={<StoryViewer />} />
            <Route path="download-app" element={<DownloadApp />} />

            <Route
              path="storiesModification/:storyId"
              element={
                <ProtectedRoute user={user}>
                  <StoryModification />
                </ProtectedRoute>
              }
            />
            <Route
              path="StoryModificationGrid"
              element={
                <ProtectedRoute user={user}>
                  <StoryModificationGrid />
                </ProtectedRoute>
              }
            />
            <Route
              path="CreateStory"
              element={
                <ProtectedRoute user={user}>
                  <CreateStory />
                </ProtectedRoute>
              }
            />
            <Route
              path="MessageBoard"
              element={
                <ProtectedRoute user={user}>
                  <MessageBoard />
                </ProtectedRoute>
              }
            />
            <Route
              path="Show"
              element={
                <ProtectedRoute user={user}>
                  <Show />
                </ProtectedRoute>
              }
            />
            <Route
              path="leaderboard"
              element={
                <ProtectedRoute user={user}>
                  <Leaderboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="uploadSVG"
              element={
                <ProtectedRoute user={user}>
                  <SVGManagerPage />
                </ProtectedRoute>
              }
            />
            <Route path="privacy" element={<PrivacyPolicy />} />
            <Route path="404" element={<div>Not Found</div>} />
            <Route path="500" element={<div>Server Error</div>} />
          </Route>

          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
          
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
