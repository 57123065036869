import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import './DrawerComponent.css'; // Import the CSS file
import DrawerContents from './drawerContents';

const drawerWidth = 240;

const DrawerComponent = ({ mobileOpen, handleDrawerToggle }) => {
  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        className="drawer-icon-button"
        sx={{ 
          display: { sm: 'none' }, 
          position: 'fixed', 
          top: 10, 
          left: 10, 
          zIndex: 1300 // Ensure it is on top of other elements
        }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { 
            boxSizing: 'border-box', 
            width: drawerWidth,
            zIndex: 1200, // Adjust z-index if necessary
          },
        }}
      >
        {DrawerContents(handleDrawerToggle)}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { 
            boxSizing: 'border-box', 
            width: drawerWidth,
          },
        }}
        open
      >
        {DrawerContents()}
      </Drawer>
    </>
  );
};

export default DrawerComponent;
