import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosClient from '../api/axiosClient'; // Import the Axios client
import { config } from '../Common/Constants';
const URL = config.url;

export const fetchStories = createAsyncThunk(
  'stories/fetchStories',
  async (_, { getState }) => {
    const state = getState();
    const userId = state?.user?.user?.uid ?? null;

    try {
      // Ensure user is authenticated and token is current
      // Construct API URL based on userId
      const New_URL = userId ? `${URL}/KahaniJson/getkahanis/${userId}` : `${URL}/KahaniJson/kahanis`;

      const response = await axiosClient.get(New_URL);

      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || error.message || "Failed to fetch stories");
    }
  }
);

const storiesSlice = createSlice({
  name: 'stories',
  initialState: {
    stories: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.stories = action.payload;
      })
      .addCase(fetchStories.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default storiesSlice.reducer;